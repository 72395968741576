<template>
    <div>
        <div >
            <template>
                <v-container>
                    <h4 v-if="!isNepaliLanguage" class="headline mt-5 primary--text text-center">
                        <strong> {{ branchInformation.branchName }} </strong> 
                    </h4>
                    <h4 v-else class="headline mt-5 primary--text text-center">
                        <strong>  {{ branchInformation.branchNameNepali }}  </strong>
                    </h4>
                    <div id="MainContainer">
                        <v-row>

                            <v-col cols="12" sm="10">
                                <v-card class="mt-5"
                                        id="slider-card"
                                        max-height="550"
                                        width="100%"
                                        :elevation="24"
                                        style="background:white">

                                    <img :src="branchInformation.logo" id="hotellogo" />
                                </v-card>
                            </v-col>
                            <v-col class="box" cols="12" sm="2">
                                <v-row class="mt-10">
                                    <v-card id="box">
                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox" class="box" @click="$router.push('/branch')" color="#077dfa" elevation="5">
                                                <p id="titleForBox">Profile</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-avatar>
                                                                <v-icon class="boxIcon" color="white">people</v-icon>
                                                            </v-list-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('profile')}}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox" class="box" @click="$router.push('/NationalParkService')" color="#006400" elevation="5">
                                                <p id="titleForBox">Ticket Category</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-avatar>
                                                                <v-icon class="boxIcon" color="white">people</v-icon>
                                                            </v-list-avatar>

                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('ticket_category')}}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox" @click="$router.push('/NationalParkCustomerList')" color="#FFA500" elevation="5">
                                                <p id="titleForBox">Ticket Issue</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-item-avatar>
                                                                <v-icon class="boxIcon" color="white">directions_run</v-icon>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('ticket_issue')}}
                                                                    </p>

                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox" @click="$router.push('/NationalParkEmployeeList')" color="#1b4f45" elevation="5">
                                                <p id="titleForBox">Employee</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-item-avatar>
                                                                <v-icon class="boxIcon" color="white">mdi-bell</v-icon>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('employee')}}
                                                                    </p>

                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-card>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <v-container>
                        <h4 v-if="!isNepaliLanguage" class="headline mt-10 text-center">
                            Visitors in {{ branchInformation.branchName }}
                        </h4>
                        <h4 v-else class="headline mt-10 text-center">
                            {{ branchInformation.branchNameNepali }}मा आगन्तुकहरू
                        </h4>
                        <v-row>
                            <v-col cols="12" md="12" class="mt-5">
                                <v-card elevation="5">
                                    <template>
                                        <div id="app">
                                            <GChart type="ColumnChart"
                                                    :data="barsData"
                                                    :options="chartOptions"
                                                    style="height: 400px; " />
                                        </div>
                                    </template>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                    <hr style="margin-top: 60px;" />
                    <!--<v-row>
                        <v-col cols="12" md="6">
                            <h1 class="headline mt-5 primary--text" style="margin-left:20px">{{branchInformation.branchName}} Visitors</h1>
                        </v-col>
                        <v-col cols="12" md="6">
                            <h1 class="headline mt-5 primary--text">{{branchInformation.branchName}} Employees</h1>
                        </v-col>
                    </v-row>-->
                    <v-container>

                        <v-row>

                            <v-col cols="12" md="6">
                                <h1 v-if="!isNepaliLanguage" class="headline mt-5 primary--text">
                                    {{ branchInformation.branchName }} Visitors
                                </h1>
                                <h1 v-else class="headline mt-5 primary--text">
                                    {{ branchInformation.branchNameNepali }}मा आगन्तुकहरू
                                </h1>
                                <div slot="table-actions">
                                    <v-btn color="primary" @click.stop="addModal">{{$t('ticket_issue')}}</v-btn>
                                </div>
                                <v-dialog v-model="dialogDelete" max-width="500px">
                                    <deleteListData :deleteFormData="deleteFormData" @formResponse="formResponse"></deleteListData>
                                </v-dialog>
                                <vue-good-table :columns="columns"
                                                :rows="rows"
                                                mode="remote"
                                                :totalRows="customerData.totalRecords"
                                                :pagination-options="{ enabled: true,mode: 'records',perPageDropdown: [5,10,15,20] }"
                                                @on-page-change="onPageChange"
                                                @on-sort-change="onSortChange"
                                                @on-per-page-change="onPerPageChange"
                                                styleClass="vgt-table condensed">
                                    <template slot="table-column" slot-scope="props">
                                        <span>
                                            {{ $t(props.column.label) }}
                                        </span>
                                    </template>
                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field == 'flightDateTime'">
                                            <span>{{props.row.flightDateTime | moment("DD-MMM-YYYY, h:mm:ss a")}}</span>
                                        </span>
                                        <span v-else-if="props.column.field == 'actions'">
                                            <v-icon small class="mr-2" @click.stop="editItem(props.row, 'CustomerForm')">edit</v-icon>
                                            <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
                                        </span>
                                    </template>
                                </vue-good-table>
                                <v-dialog v-model="snackbar.formModal"
                                          fullscreen
                                          hide-overlay
                                          transition="dialog-bottom-transition"
                                          persistent>
                                    <v-card>
                                        <!-- <component :is="snackbar.dynamicComponent.current"
                       @dialogueClose="dialogueClose"
                       @formResponse="formResponse"
                       v-bind="customerData.updateItem"
            ></component>-->
                                        <CustomerForm @dialogueClose="dialogueClose"
                                                      @formResponse="formResponse"
                                                      v-if="snackbar.createFormComponent"></CustomerForm>
                                        <CustomerEditForm @dialogueClose="dialogueClose"
                                                          @formResponse="formResponse"
                                                          v-if="snackbar.editFormComponent"
                                                          :editItemIndex="customerData.editItemIndex"></CustomerEditForm>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" md="6">
                                <h1 v-if="!isNepaliLanguage" class="headline mt-5 primary--text">
                                    {{ branchInformation.branchName }} Employees
                                </h1>
                                <h1 v-else class="headline mt-5 primary--text">
                                    {{ branchInformation.branchNameNepali }}मा कर्मचारी
                                </h1>
                                <v-card>
                                    <v-list style="background-color: #077dfa">
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <v-title id="table" class="white--text"><strong>{{$t('s_no')}}</strong></v-title>
                                            </v-list-item-avatar>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="white--text"><strong> {{$t('full_name')}}</strong> </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class=" white--text"><strong> {{$t('employee_email')}} </strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class=" white--text"><strong>{{$t('role')}}</strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="white--text"><strong>{{$t('active')}}</strong></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                                <v-card style="overflow-y: scroll; height: 350px;">
                                    <v-list v-for="(user, i) in userList"
                                            :key="i">
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <v-title id="table" class="black--text">{{i+1}}</v-title>
                                            </v-list-item-avatar>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="black--text">{{user.firstName}} </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="mr-18 black--text"> {{user.email}}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class=" black--text"> {{user.role}}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="black--text"> {{user.activeUser}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>


                    <v-container>
                        <h4 v-if="!isNepaliLanguage" class="headline md-10 text-center mt-5">
                            Monthly Visitors in {{ branchInformation.branchName }}
                        </h4>
                        <h4 v-else class="headline md-10 text-center mt-5">
                            {{ branchInformation.branchNameNepali }}मा मासिक आगन्तुकहरू
                        </h4>
                        <v-row>

                            <v-col cols="12" md="12" class="mt-5">
                                <template>
                                    <v-card elevation="5">
                                        <div id="app">
                                            <GChart type="ColumnChart"
                                                    :data="customerDatas"
                                                    :options="chartOptions"
                                                    style="height: 400px;" />
                                        </div>
                                    </v-card>
                                </template>
                            </v-col>


                        </v-row>
                    </v-container>
                </v-container>
            </template>
        </div>
    </div>
</template>
<script>
    import { mdiClock, mdiStore, mdiNaturePeople, mdiHead } from "@mdi/js";
    import axios from "axios"
    import { mapGetters } from "vuex";
    import publicAxios from '../../../simple-axios'
    import CustomerForm from "../Customer/CustomerForm";
    import CustomerEditForm from "../Customer/CustomerEditForm";
    import deleteListData from "@/components/deleteModal";
    export default {
        props: [
            "btn-text"
        ],
        computed: {
            ...mapGetters(['getSystemUserData']),
            isDivision() {
                return this.getSystemUserData.role === 'Division'
            },
            isProvinceModule() {
                return this.getSystemUserData.role === 'ProvinceModule'
            },
            isAssociation() {
                return this.getSystemUserData.role === 'Association'
            },
            isBranchData() {
                return this.getSystemUserData.BranchID
            },
            isNepaliLanguage() {
                return this.$i18n.locale === "ne";
            }
        },
        data: () => ({
            reload: [],
            callbackResponse: {
                timeout: 6000
            },
            columns: [
                {
                    label: "s_no",
                    field: "countIndex"
                },
                {
                    label: 'full_name',
                    field: 'fullName'
                },
                {
                    label: 'gender',
                    field: 'gender',
                },
                {
                    label: 'nationality',
                    field: 'nationality',
                },
                {
                    label: 'ticket_no',
                    field: 'ticketNumber',
                },
                {
                    label: 'issued_date',
                    field: 'issuedDate',
                },
                {
                    label: "actions",
                    field: "actions"
                }
            ],
            rows: [],
            dialogDelete: false,
            deleteFormData: [],
            formdata: [],
            userList: [],
            customerData: {
                totalRows: 0,
                editItemIndex: null
            },
            customerDatas: [],
            serverParams: {
                search: "",
                columnFilters: {},
                sort: [
                    {
                        field: "personInformationID",
                        type: "desc"
                    }
                ],
                page: 1,
                perPage: 10
            },
            snackbar: {
                color: '',
                snackbar: false,
                text: '',
                timeout: 6000,
                isLoading: false,
                formModal: false,
                createFormComponent: false,
                editFormComponent: false,
                breadcrumbsItem: [
                    {
                        text: 'Dashboard',
                        disabled: false,
                        to: 'NationalParkDashboard',
                        exact: true

                    },
                    {
                        text: 'National Park Customer Information',
                        disabled: true,
                    },
                ],
                dynamicComponent: {
                    current: 'CustomerForm'
                }
            },



            hotelBranchId: [],
            chartData: [],
            chartOptions: {
                chart: {
                    title: "Company Performance",
                    subtitle: "Sales, Expenses, and Profit: 2014-2020",
                },
            },
            barData: [],
            branchInformation: [],
            barsData: [],
            RoomData: [],
            width: 2,
            radius: 10,
            padding: 8,
            lineCap: "round",
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            fill: false,
            type: "trend",
            autoLineWidth: false,
            svgClock: mdiClock,
            svgStore: mdiStore,
            svgCustomer: mdiNaturePeople,
            svgHeading: mdiHead
        }),
        components: {
            'CustomerForm': CustomerForm,
            'CustomerEditForm': CustomerEditForm,
            deleteListData
        },

        methods: {

            getCompleteData() {
                this.loadItems();
                this.getParkCustomerCountryWise()
                this.getParkCustomerMonthlyWise()
                this.getBranchEmployeeDetails()
                this.dashboardData()
            },
            fullName(rowObj) {
                return rowObj.firstName + '   ' + rowObj.lastName
            },
            formResponse(data) {
                console.log({ data })
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.reloadPage();
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },
            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },
            dialogueClose() {
                this.customerData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Country: this.serverParams.columnFilters.country,
                    FirstName: this.serverParams.columnFilters.firstName,
                    CitizenShipNumber: this.serverParams.columnFilters.citizenShipNumber,
                    PassportNumber: this.serverParams.columnFilters.passportNumber,
                    LicenseNumber: this.serverParams.columnFilters.licenseNumber
                };
                axios.post('NationalPark/GetDailyCustomerInformationListAsync', param).then(response => {
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                    this.snackbar.loading = false;

                })
                    .catch(err => {
                        console.log(err)
                    });
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.customerData.editItemIndex = item.personInformationID
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "NationalPark/DeleteCustomerInformationByIDAsync/" + props.personInformationID;
            },



            async dashboardData() {
                const dashboardData = await axios.get(
                    "Branch/GetBranchInformationByBranchID"
                );

                this.branchInformation = dashboardData.data;
                console.log("this.branchDetails", this.branchInformation);
            },
            async getParkCustomerCountryWise() {
                const { data } = await publicAxios.get('NationalPark/GetNationalParkCustomerCountrywisecountAsync')
                let formatedData = this.getChartData(data)
                this.barsData = formatedData
                console.log('this.HotelCustomerCountrywisecountAsync', this.barsData)
            },

            async getParkCustomerMonthlyWise() {
                const { data } = await publicAxios.get('NationalPark/GetNationalParkCustomerMonthWise')
                let formatedData = this.getChartData(data)
                this.customerDatas = formatedData
                console.log('this.GetNationalParkCustomerMonthWise', this.customerDatas)
            },

            async getBranchEmployeeDetails() {
                const { data } = await publicAxios.post("NationalPark/UserList")
                this.userList = data
                console.log('this.userlist', this.userList)
            },
            getChartData(data) {
                console.log(data)
                let arr = []
                arr[0] = Object.keys(data[0])
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1])
                }
                return arr;
            },
            reloadPage() {
                this.getCompleteData();
                this.reload += 1;
                //this.$router.go();
            },
        },
        created() {
           
        },
        mounted() {
            this.getCompleteData();
            //this.loadItems()
        }

    };
</script>
<style scoped>
    ::-webkit-scrollbar {
        width: 3px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

    .v-progress-circular {
        margin: 1rem;
    }

    @media screen and (min-width:1903px) {
        #sideBox {
            font-size: 16px;
            color: white;
        }

        #titleForBox {
            display: none;
            text-align: center;
        }

        .boxIcon {
            font-size: 36px;
        }

        #hotellogo {
            width: 100%;
            height: 550px;
            object-fit: fill;
        }

        #box {
            height: 500px;
        }

        #MainContainer {
            margin-left: 90px;
        }
    }

    @media screen and (max-width:1903px) {
        #sideBox {
            font-size: 16px;
            color: white;
        }

        #titleForBox {
            display: none;
            text-align: center;
        }

        #table {
            font-size: 12px !important;
        }

        .boxIcon {
            font-size: 26px;
        }

        #hotellogo {
            width: 890px;
            height: 550px;
            object-fit: fill;
        }

        #box {
            height: 500px;
        }

        #MainContainer {
            margin-left: 90px;
        }
    }

    @media screen and (max-width:1263px) {
        #sideBox {
            font-size: 12px;
            color: white;
        }

        .boxIcon {
            font-size: 20px;
        }

        #box {
            height: 430px;
        }

        #table {
            font-size: 10px !important;
        }

        #sideMainBox {
            height: 80px;
        }

        #table {
            font-size: 12px !important;
        }

        #hotellogo {
            width: 650px;
            height: 550px;
            object-fit: fill;
        }

        #MainContainer {
            margin-left: 90px;
        }
    }


    @media screen and (max-width:979px) {
        #sideBox {
            font-size: 10px;
            color: white;
        }

        #sideMainBox {
            height: 70px;
        }

        #box {
            height: 395px;
        }

        #table {
            font-size: 10px !important;
        }

        #hotellogo {
            width: 100%;
            height: 550px;
            object-fit: fill;
        }

        #MainContainer {
            margin-left: 70px;
        }
    }

    @media screen and (max-width: 860px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #sideMainBox {
            height: 70px;
            width: 100%;
            padding: 25px;
        }

        .boxIcon {
            /*  font-size: 26px;
            margin-bottom:15px;*/
            display: none;
        }

        #titleForBox {
            color: white;
            display: block;
            text-align: center;
        }

        #box {
            width: 100%;
            height: 445px;
        }

        #hotellogo {
            width: 100%;
            height: 550px;
            object-fit: fill;
        }

        .col-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        #MainContainer {
            margin-left: 40px;
        }
    }

    @media screen and (max-width: 750px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #sideMainBox {
            height: 70px;
            width: 100%;
        }


        #box {
            width: 100%;
            height: 475px;
        }

        #hotellogo {
            width: 100%;
            height: 450px;
            object-fit: fill;
        }

        .col-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        #MainContainer {
            margin-left: 40px;
        }
    }

    @media screen and (max-width:640px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #hotellogo {
            width: 100%;
            height: 350px;
            object-fit: fill;
        }
    }


    @media screen and (max-width:599px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #hotellogo {
            width: 100%;
            height: 250px;
            object-fit: fill;
        }
    }
</style>




